
import { Component, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';

import { LessonsService } from 'src/app/services/lessons/lessons.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-repeat-booking',
  standalone: true,
  imports: [MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatButtonModule,TranslateModule],
  templateUrl: './edit-repeat-booking.component.html',
  styleUrl: './edit-repeat-booking.component.scss'
})

export class EditRepeatBookingComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: {studentId: string, teacherId: string, studentName:string},
    public lessonsService:LessonsService, public translate:TranslateService){}
    deleteFutureBookings(){
        this.lessonsService.deleteRepeatedBookings(this.data.teacherId,this.data.studentId).subscribe((res)=>{
            this.lessonsService.updateBookingList$.next();
        });
        
    }
}
