<mat-dialog-content class="feedback-container">
	<h2 class="feedback-title">{{ "popUp.lesson-summary" | translate }}</h2>

	<div class="mb-6">
		<p class="feedback-paragraph">{{ "popUp.lesson-question" | translate }}</p>
		<div class="flex justify-between">
			<button class="focus:outline-none" (click)="selectEmoji(5)" aria-label="Sehr gut">
				<div class="emoji-container" [ngClass]="{ 'bg-gray-200': selectedEmoji !== 5, 'bg-blue-200': selectedEmoji === 5 }">
					<span class="emoji-size" aria-hidden="true">😃</span>
				</div>
			</button>
			<button class="focus:outline-none" (click)="selectEmoji(4)" aria-label="Gut">
				<div class="emoji-container" [ngClass]="{ 'bg-gray-200': selectedEmoji !== 4, 'bg-blue-200': selectedEmoji === 4 }">
					<span class="emoji-size" aria-hidden="true">🙂</span>
				</div>
			</button>
			<button class="focus:outline-none" (click)="selectEmoji(3)" aria-label="Neutral">
				<div class="emoji-container" [ngClass]="{ 'bg-gray-200': selectedEmoji !== 3, 'bg-blue-200': selectedEmoji === 3 }">
					<span class="emoji-size" aria-hidden="true">🫤</span>
				</div>
			</button>
			<button class="focus:outline-none" (click)="selectEmoji(2)" aria-label="Nicht so gut">
				<div class="emoji-container" [ngClass]="{ 'bg-gray-200': selectedEmoji !== 2, 'bg-blue-200': selectedEmoji === 2 }">
					<span class="emoji-size" aria-hidden="true">😕</span>
				</div>
			</button>
			<button class="focus:outline-none" (click)="selectEmoji(1)" aria-label="Schlecht">
				<div class="emoji-container" [ngClass]="{ 'bg-gray-200': selectedEmoji !== 1, 'bg-blue-200': selectedEmoji === 1 }">
					<span class="emoji-size" aria-hidden="true">🙁</span>
				</div>
			</button>
		</div>
		<div *ngIf="emojiError" class="text-red-500 text-sm mt-2">{{ "popUp.emoji-error" | translate }}.</div>
	</div>

	<div class="mb-6">
		<label for="status" class="feedback-label">Status</label>
		<select id="status" [(ngModel)]="status" (change)="statusError = false" class="status-list" required>
			<option value="" disabled>{{ "popUp.select" | translate }}</option>
			<option value="completed_as_planned">{{ "lessons-overview.lesson-status.completed_as_planned" | translate }}</option>
			<option value="student_cancelled_24_hours">
				{{ "lessons-overview.lesson-status.student_cancelled_24_hours" | translate }}
			</option>
			<option value="student_not_appeared">{{ "lessons-overview.lesson-status.student_not_appeared" | translate }}</option>
			<option value="scheduled_by_mistake">{{ "lessons-overview.lesson-status.scheduled_by_mistake" | translate }}</option>
			<option value="teacher_canceled">{{ "lessons-overview.lesson-status.teacher_canceled" | translate }}</option>
		</select>
		<div *ngIf="statusError" class="text-red-500 text-sm mt-2">{{ "popUp.status-error" | translate }}.</div>
	</div>

	<!-- <div class="mb-6">
		<label for="content" class="block text-md font-medium mb-2 text-gray-700">Unterrichtsinhalt (wird mit dem Schüler geteilt)</label>
		<textarea
			id="content"
			[(ngModel)]="content"
			rows="4"
			class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
			placeholder="Eine kurze Zusammenfassung des Unterrichtsinhaltes"
		></textarea>
	</div> -->

	<div class="mb-6">
		<label for="feedback" class="feedback-label">{{ "popUp.feedback-label" | translate }}</label>
		<textarea
			id="feedback"
			[(ngModel)]="feedbackSirius"
			(input)="feedbackError = false"
			rows="4"
			class="feedback-textarea"
			placeholder="{{ 'popUp.feedback-placeholder' | translate }}"
		></textarea>
		<!-- <div *ngIf="feedbackError" class="text-red-500 text-sm mt-2">Bitte hinterlasse ein Feedback.</div> -->
	</div>

	<div class="flex justify-between">
		<button class="feedback-button__cancel" (click)="onCancel()">{{ "popUp.cancel" | translate }}</button>
		<button
			(click)="onSubmit()"
			class="button-blue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
			[disabled]="!isFormValid()"
		>
			{{ "popUp.submit" | translate }}
		</button>
	</div>
</mat-dialog-content>
