import { environment } from "src/environments/environment";
import { MASTER } from "./environment-constants";
import { logger } from "src/app/lib-core/logger";
import { API_URL } from "../lib-core/constants/constants";

export const MONTHLY = environment.ENV === MASTER ? 641251 : 7801;
export const YEARLY = environment.ENV === MASTER ? 641266 : 7800;
export const VENDOR_ID = environment.ENV === MASTER ? 124955 : 903;
export const DAILY_DEBUG = 7955;
export const IS_SANDBOX = environment.ENV !== MASTER;

logger.groupCollapsed("Environment");
logger.log(
	"Debug ENV: ",
	environment.ENV,
	"\nMONTHLY: ",
	MONTHLY,
	"\nYEARLY: ",
	YEARLY,
	"\nVENDOR_ID: ",
	VENDOR_ID,
	"\nIS_SANDBOX: ",
	IS_SANDBOX
);
logger.groupEnd();

export const ROOMS_URL = API_URL + "/rooms";
export const IS_LOGGED_IN = API_URL + "/auth/authorized";
export const AUTH_URL = API_URL + "/auth/authorization";
export const AUTH_ADMIN_URL = API_URL + "/auth/admin/authorization";
export const SIGN_UP = API_URL + "/auth/signup";
export const TEACH_SIGN_UP = API_URL + "/auth/teach-signup";
export const CHECK_CONFIRMATION = API_URL + "/teachers/confirmation/check";
export const REGISTRATION = API_URL + "/auth/registration";
export const RECOVERY = API_URL + "/auth/recovery";
export const LOG_OUT = API_URL + "/auth/logout";
export const GET_USER_PROFILE = API_URL + "/users/profile";
export const PUT_TEACHER_PROFILE = API_URL + "/teachers/profile";
export const PUT_STUDENT_PROFILE = API_URL + "/students/profile";
export const TIMEZONES = API_URL + "/teachers/timezones";
export const PROMOTION = API_URL + "/teachers/promotion";
export const LANGUAGES = API_URL + "/languages";
export const ROOMS = API_URL + "/rooms";
export const PERSONAL_ROOM = API_URL + "/rooms";
export const ADD_PERSONAL_ROOM = API_URL + "/rooms/personal";
export const GET_PERSONAL_ROOM = API_URL + "/rooms/personal";
export const RECORDING = API_URL + "/recordings";
export const REGULAR_STUDENTS = API_URL + "/matches/:userId/students";
export const REGULAR_TEACHERS = API_URL + "/matches/:userId/teachers";
export const GET_NOTES = API_URL + "/note/:userId";
export const ADD_NOTE = API_URL + "/note";
export const DELETE_NOTE = API_URL + "/note/:noteId";
export const EDIT_NOTE = API_URL + "/note/:noteId";
export const STRIPE_SESSION = API_URL + "/students/stripe-portal";
export const REGISTRATION_CONFIRM = "/registration";
export const RECOVERY_CONFIRM = "/recovery";
export const IS_ROOM_FULL = API_URL + "/rooms/:id";
export const TOWER_IS_ROOM_FULL = API_URL + "/tower/rooms/:id";
export const TOKEN_REFRESH = API_URL + "/auth/token";
export const BOOKING = API_URL + "/booking";
export const MATCHES = API_URL + "/matches";
export const AVAILABILITY = API_URL + "/availability";
export const AVAILABILITY_SLOTS = API_URL + "/availability/:userId";
export const AVAILABILITY_SLOTS_REGULAR_LESSON =
	API_URL +
	"/availability/:userId/slots?type=regular&considerAvailability=true&considerRestriction=false";
export const CHAT_ROOM_ID = API_URL + "/chats";
export const AVAILABILITIES_REGULAR_LESSON =
	API_URL + "/availability/:userId?type=regular";
export const BOOKING_FEEDBACK = API_URL + "/booking-feedback/:bookingId";
export const LESSON_PACKAGE = API_URL + "/lessons/:email";
export const INVITE_EXTERNAL_STUDENT = API_URL + "/students";
export const MATCHING = API_URL + "/matches";
export const PAYMENT_SESSION = API_URL + "/students/:studentId/payment-session";
export const POST_MIXPANEL_EVENT = API_URL + "/students/mixpanel-event";
export const STUDENT_ACTIVATION_EMAIL = API_URL + "/auth/resend-invite";
export const DELETE_REPEAT_BOOKING =
	API_URL + "/booking/regular/:teacherId/:studentId";
export const PACKAGE = API_URL + "/lessons/package";
export const TEACHERS_REVIEW = API_URL + "/teachers/review/:userId";

// Uncomment for debug
/*logger.log('RTC_WS_URL: ', RTC_WS_URL);
logger.log('WS_URL: ', WS_URL);
logger.log('API_URL: ', API_URL);
logger.log('ROOMS_URL: ', ROOMS_URL);
logger.log('AUTH_URL: ', AUTH_URL);*/

export const RTC_ICE_CANDIDATE = {
	iceServers: [
		{
			urls: "stun:stun.relay.metered.ca:80"
		},
		{
			urls: "turn:eu.relay.metered.ca:80",
			username: "8bd794cd2eba744eb042ca71",
			credential: "3X0ESKh8HBz+H9/G"
		},
		{
			urls: "turn:eu.relay.metered.ca:80?transport=tcp",
			username: "8bd794cd2eba744eb042ca71",
			credential: "3X0ESKh8HBz+H9/G"
		},
		{
			urls: "turn:eu.relay.metered.ca:443",
			username: "8bd794cd2eba744eb042ca71",
			credential: "3X0ESKh8HBz+H9/G"
		},
		{
			urls: "turns:eu.relay.metered.ca:443?transport=tcp",
			username: "8bd794cd2eba744eb042ca71",
			credential: "3X0ESKh8HBz+H9/G"
		}
	]
};
