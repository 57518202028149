import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, of } from "rxjs";

import {
	GET_USER_PROFILE,
	LANGUAGES,
	TIMEZONES,
	PROMOTION,
	PUT_STUDENT_PROFILE,
	PUT_TEACHER_PROFILE,
	TEACHERS_REVIEW
} from "../constants/connection-constants";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { map, mergeMap } from "rxjs/operators";
import { Profile, TeacherStatus } from "../types/profile.types";
import { isStudentSubrole } from "../constants/subroles-constants";
import { profileMap } from "./profile-map";
import { environment } from "@env/environment";

let userId: Subject<string>;
@Injectable({
	providedIn: "root"
})
export class ProfileService {
	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	get(): Observable<Profile> {
		if (!this.auth.accessToken) {
			return of(null);
		}
		return this.http.get(GET_USER_PROFILE, this.authHttpOptions).pipe(
			map((profile: Profile | any) => {
				let profileFixed = profileMap(profile);
				localStorage.setItem("subrole", String(profileFixed.subrole));
				return profileFixed;
			})
		);
	}

	save(data: any): Observable<object> {
		if (!this.auth.accessToken) {
			return of(null);
		}
		return this.getSubrole().pipe(
			mergeMap((subrole) =>
				this.putProfile(data, subrole, this.authHttpOptions)
			)
		);
	}

	savePhoto(photo: File): Observable<object> {
		const formData = new FormData();
		formData.set("photo", photo, photo.name);
		return this.getSubrole().pipe(
			mergeMap((subrole) =>
				this.putProfile(formData, subrole, this.authFormDataOptions)
			)
		);
	}

	saveInstrumentCert(file: File): Observable<object> {
		const formData = new FormData();
		formData.set("certificate", file, file.name);
		return this.putProfile(formData, 3, this.authFormDataOptions);
	}

	getLanguages(): Observable<object> {
		return this.http.get(LANGUAGES, this.httpOptions);
	}

	getTimezones(): Observable<object> {
		return this.http.get(TIMEZONES, this.httpOptions);
	}

	getPuddleOverrideUrl(email): Observable<any> {
		return this.http.post(PROMOTION, { email });
	}

	private putProfile(
		data: any,
		subrole: number,
		options
	): Observable<object> {
		const url = isStudentSubrole(subrole)
			? PUT_STUDENT_PROFILE
			: PUT_TEACHER_PROFILE;

		return this.http.put(url, data, options);
	}

	private getSubrole(): Observable<number> {
		const subrole = localStorage.getItem("subrole");
		if (subrole) {
			return of(Number(subrole));
		} else {
			return this.get().pipe(map((profile: Profile) => profile.subrole));
		}
	}

	private httpOptions = {
		withCredentials: true
	};

	private get authHttpOptions() {
		return {
			...this.httpOptions,
			headers: new HttpHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: this.auth.accessToken || ""
			})
		};
	}

	private get authFormDataOptions() {
		return {
			...this.httpOptions,
			headers: this.auth.formDataHeaders
		};
	}

	public getUserId(): Observable<string> {
		return userId.asObservable();
	}

	public reviewTeacher(id: string): Observable<any> {
		const opts = {
			...this.httpOptions,
			headers: new HttpHeaders({
				"Content-Type": "application/json; charset=utf-8",
				"bot-key": environment.botkey,
				"bot-type": "webapp"
			})
		};

		const url = TEACHERS_REVIEW.replace(":userId", id);
		return this.http.put(
			url,
			{
				teacherStatus: TeacherStatus.REVIEW
			},
			opts
		);
	}
}
