import { Component, Inject } from "@angular/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { CommonModule } from "@angular/common";
import {
	FormsModule,
	ReactiveFormsModule,
	FormControl,
	FormGroup
} from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { LessonsService } from "../services/lessons/lessons.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ButtonType } from "../components/call-room/data/ButtonType";
import { PopupService } from "../services/popup/popup.service";
import { Student } from "../students/students.service";
import { MatInputModule } from "@angular/material/input";

export enum repeatType {
	DAILY = "daily",
	WEEKLY = "weekly",
	BIWEEKLY = "bi-weekly",
	MONTHLY = "monthly",
	// to do : make not falsy!
	NONE = ""
}

export enum RequestType {
	ADD = "add",
	UPDATE = "update"
}

export const REPEAT_TYPE_LIST = [
	{
		value: repeatType.NONE,
		title: "lessons.onetime"
	},
	{
		value: repeatType.DAILY,
		title: "lessons.daily"
	},
	{
		value: repeatType.WEEKLY,
		title: "lessons.weekly"
	},
	{
		value: repeatType.BIWEEKLY,
		title: "lessons.bi-weekly"
	},
	{
		value: repeatType.MONTHLY,
		title: "lessons.monthly"
	}
];

@Component({
	selector: "app-date-and-time",
	standalone: true,
	imports: [
		NgxMaterialTimepickerModule,
		MatFormFieldModule,
		MatDatepickerModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatCardModule,
		MatNativeDateModule,
		MatSelectModule,
		TranslateModule,
		MatInputModule
	],
	templateUrl: "./date-and-time.component.html",
	styleUrl: "./date-and-time.component.scss"
})
export class DateAndTimeComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { type; student; userId; dialog; bookingId },
		public lessonsService: LessonsService,
		public popupService: PopupService,
		public translateService: TranslateService
	) {}

	repeatTypeList = REPEAT_TYPE_LIST;

	studentList: Student[];
	minDate = new Date();
	maxDate = new Date();
	date = new Date();
	public disabled = false;
	shouldHideSelectContact;
	selected: Date;
	selectedTimeHour: string = "10";
	selectedTimeMinute: string = "00";
	time = new FormGroup({
		startTime: new FormControl("12:00"),
		showStartTimeListBox: new FormControl(false)
	});
	confirmationTitle: string;
	timeString = "16:00";
	dateChanged: boolean = false;
	textareaValue = "";
	bookingID: string;
	type: "booking" | "reschedule";
	todayDate: Date = new Date();
	repeatTypeValue = new FormControl(repeatType.NONE);

	ngOnInit() {
		this.confirmationTitle = this.translateService.instant(
			"lessons.confirmation-title"
		);
		this.type = this.data.type;
	}

	public dateControl = new FormControl(
		new Date(
			this.date.getFullYear(),
			this.date.getMonth(),
			this.date.getDate(),
			this.date.getHours(),
			0
		)
	);

	datePickerOnOpen() {
		this.shouldHideSelectContact = true;
	}

	datePickerOnClosed() {
		this.shouldHideSelectContact = false;
	}

	timeChange(time) {
		this.timeString = time;
	}

	dateChange(date) {
		this.dateChanged = true;
		this.date = date;
	}

	nextButton(type: "booking" | "reschedule") {
		this.lessonsService
			.getLessonPackageByStudentEmail(this.data.student.email)
			.subscribe((lessonPackage) => {
				const { duration } = lessonPackage;
				const durationMinutes = Number(duration);
				const startTime = new Date(this.date);
				var [hours, minutes] = this.timeString.split(":").map(Number);
				startTime.setHours(hours);
				startTime.setMinutes(minutes);
				const endDate = new Date(startTime);
				endDate.setMinutes(startTime.getMinutes() + durationMinutes);
				const endTime = new Date(endDate.getTime());
				const start = startTime.toUTCString();
				const end = endTime.toUTCString();

				if (type == "booking") {
					const bookingData = {
						studentId: this.data.student.id,
						teacherId: this.data.userId,
						description: "",
						title: "",
						startTime: new Date(start).toISOString(),
						endTime: new Date(end).toISOString(),
						duration: duration + "m",
						type:
							this.repeatTypeValue.value == repeatType.NONE
								? "single"
								: "repeat",
						status: "accepted",
						notify: true
					};

					// add repeat type if
					if (this.repeatTypeValue.value !== repeatType.NONE) {
						bookingData["repeat"] = true;
						bookingData["repeatSchema"] = {
							rhythm: this.repeatTypeValue.value,
							amount: 3,
							startTime: new Date(start).toISOString(),
							endTime: new Date(end).toISOString(),
							duration: duration + "m"
						};
					}

					const viewValue =
						this.repeatTypeList.find(
							(item) => item.value === this.repeatTypeValue.value
						)?.title || "";

					let confirmData = {
						student: this.data.student,
						date: bookingData.startTime,
						duration: bookingData.duration,
						target: "teacher",
						title: this.confirmationTitle,
						timeZone: "EU",
						repeatType: viewValue
							? this.translateService.instant(viewValue)
							: "",
						requestType: RequestType.ADD
					};

					// Todo get rid of callback

					this.popupService.openConfirmLessonDialog(
						confirmData,
						bookingData,
						[
							{
								type: ButtonType.CANCEL,
								text: "lessons.cancel-at-confirmation"
							},
							{
								type: ButtonType.OK,
								text: "lessons.confirm-btn"
								// callBack: () => {
								// 	this.lessonsService
								// 		.addBooking(bookingData)
								// 		.subscribe(() => {
								// 			const dialog =
								// 				this.data.dialog.getDialogById(
								// 					"confirm-lesson-dialog"
								// 				);
								// 			this.lessonsService.updateBookingList$.next();
								// 			dialog.close();
								// 		}, this.handleError);
								// }
							}
						]
					);
					this.data.dialog
						.getDialogById("date-and-time-dialog")
						.close();
				} else if (type == "reschedule") {
					const bookingData = {
						startTime: new Date(start).toISOString(),
						endTime: new Date(end).toISOString(),
						duration: duration + "m",
						rescheduleReason: this.textareaValue,
						status: "rescheduled"
					};

					let confirmData = {
						date: bookingData.startTime,
						student: this.data.student,
						duration: bookingData.duration,
						title: this.confirmationTitle,
						timeZone: "EU",
						bookingId: this.data.bookingId,
						requestType: RequestType.UPDATE
					};

					this.popupService.openConfirmLessonDialog(
						confirmData,
						bookingData,
						[
							{
								type: ButtonType.CANCEL,
								text: "lessons.cancel-at-confirmation"
							},
							{
								type: ButtonType.OK,
								text: "lessons.confirm-reschedule",
								callBack: () => {}
							}
						]
					);
					this.data.dialog
						.getDialogById("date-and-time-dialog")
						.close();
				}
			});
	}

	private handleError(error) {
		if (error?.status === 400) {
			this.popupService.openCustomMessagePopup(
				`lessons.warning-popup`,
				[
					{
						type: ButtonType.CANCEL,
						text: "Ok"
					}
				],
				false,
				true
			);
		} else {
			this.popupService.openCustomMessagePopup(
				`lessons.error-popup`,
				[
					{
						type: ButtonType.CANCEL,
						text: "Ok"
					}
				],
				false,
				true
			);
		}
	}

	selectSlot(slot, control, timeControl, showTimeListBoxControl, type) {
		timeControl.setValue(slot);
		showTimeListBoxControl.setValue(false);
	}

	close(control: FormControl<boolean>) {
		control.setValue(false);
	}
}
