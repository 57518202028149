import { Profile, TeacherStatus } from "../types/profile.types";

/**
 * Flattens the profile object to a single level.
 */
export function profileMap(source): Profile {
	const commonProperties = {
		userId: source.id,
		firstName: source.firstName,
		lastName: source.lastName,
		timezone: source.timezone,
		email: source.email,
		subrole: source.subroleId,
		languageId: source.languageId,
		betaTesting: source.betaTesting,
		spokenLanguages: source.spokenLanguages,
		subscription: source.subscription || {
			type: "free",
			left: 0
		},
		termsConfirmation: source.termsConfirmation,
		photo: source.photo,
		country: source.country
	};

	const teacherProperties = source.teacher
		? {
				instruments: source.teacher.instruments,
				musicGenres: source.teacher.musicGenres,
				lessonOverview: source.teacher.lessonOverview,
				teacherWorkload: source.teacher.teacherWorkload,
				notes: source.teacher.notes,
				gender: source.gender,
				dob: source.teacher.dob,
				socials: source.teacher.socials,
				phoneNumber: source.phoneNumber,
				accountName: source.teacher.accountName,
				bestMatch: source.teacher.bestMatch,
				taxNumber: source.teacher.taxNumber,
				taxAddress: source.teacher.taxAddress,
				vatStatus: source.teacher.vatStatus,
				iban: source.teacher.iban,
				bic: source.teacher.bic,
				enablePersonalRoom: source.teacher.enablePersonalRoom,
				teachingExperience: source.teacher.teachingExperience,
				studentPreferences: source.teacher.studentPreferences,
				preferenceComment: source.teacher.preferenceComment,
				teacherIntroduction: source.teacher.teacherIntroduction,
				notifications: source.teacher.notifications,
				motivateStudents: source.teacher.motivateStudents,
				device: source.teacher.device,
				browser: source.teacher.browser,
				uploadMbps: source.teacher.uploadMbps,
				externalMic: source.teacher.externalMic,
				externalCamera: source.teacher.externalCamera,
				secondCamera: source.teacher.secondCamera,
				matchesNumberPreference: source.teacher.matchesNumberPreference,
				gendersPreference: source.teacher.gendersPreference,
				agesPreference: source.teacher.agesPreference,
				skillLevelPreference: source.teacher.skillLevelPreference,
				payoutDetailsComplete: source.teacher.payoutDetailsComplete,
				criminalHistoryConfirmation:
					source.teacher.criminalHistoryConfirmation,
				ageConfirmation: source.teacher.ageConfirmation,
				allowExternals: source.teacher.allowExternals,
				teacherStatus: source.teacher.teacherStatus
		  }
		: {};

	const studentProperties = source.student
		? {
				instruments: source.student.instruments,
				musicGenres: source.student.musicGenres
		  }
		: {};

	return {
		...commonProperties,
		...teacherProperties,
		...studentProperties
	};
}
