import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class IconService {

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    const assetsVersion = environment.assetsVersion;
    this.matIconRegistry.addSvgIcon('cam',        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/cam.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('cam-off',    this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/cam-off.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('mic',        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/mic.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('mic-off',    this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/mic-off.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('switch-cam', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/switch-cam.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('add',        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/add.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('chat',       this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/chat.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('notes',       this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/notes.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('settings',   this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/settings.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('tuner',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/tuner.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('share',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/share.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('share-off',  this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/share-off.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('metronome',  this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/metronome.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('sound',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/sound.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('piano',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/piano.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('speakerview',this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/speakerview.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('galeryview', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/galeryview.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('leave',       this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/leave.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('emoji', 	    this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/emoji.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('record', 	    this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/record.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('record-on', 	    this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/record-on.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('exit-fullscreen', 	    this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/exit-fullscreen.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('check',       this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/pricing/check.svg?${assetsVersion}`));
	this.matIconRegistry.addSvgIcon('next',       this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/next.svg?${assetsVersion}`));
	this.matIconRegistry.addSvgIcon('person',       this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/common/person.svg?${assetsVersion}`));

    this.matIconRegistry.addSvgIcon('close',      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chat/close.svg'));
    this.matIconRegistry.addSvgIcon('vector-down',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/common/Vector.svg?${assetsVersion}`));
    this.matIconRegistry.addSvgIcon('service-icon',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/call-controls/youtube.svg?${assetsVersion}`));
	this.matIconRegistry.addSvgIcon('lesson-calendar',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/lessons/calendar.svg?${assetsVersion}`));
	this.matIconRegistry.addSvgIcon('lesson-map-pin',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/lessons/map-pin.svg?${assetsVersion}`));

	this.matIconRegistry.addSvgIcon('calendar-person',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/calendar/person.svg?${assetsVersion}`));
	this.matIconRegistry.addSvgIcon('clock',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/calendar/clock.svg?${assetsVersion}`));
	this.matIconRegistry.addSvgIcon('repeat',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/calendar/repeat.svg?${assetsVersion}`));
	this.matIconRegistry.addSvgIcon('time',      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/calendar/time.svg?${assetsVersion}`));



  }
}
