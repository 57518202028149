<div class="main-container text-center">
	<img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExYTQ3NTJweXV6dWdudmhnNHFrdWRzc3VzOXE3ZXo3b3gxNHBlN2tpZCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o6Zt8qDiPE2d3kayI/giphy.gif" alt="" class="mb-8 mx-auto" />
	<div class="text-center font-bold text-[20px] leading-[28px]">
		Hurra, der erste Schritt zu Deinem Ziel ist getan. 👏 🎶 🌟
		<br />
		<br />
		@if (teacher){
		Für deinen Unterricht bei {{teacher}} geben wir dir zusätzlich 35% Rabatt wenn du innerhalb der nächsten 24 Stunden buchst!
		} @else {
		Für deinen Unterricht geben wir dir zusätzlich 35% Rabatt wenn du innerhalb der nächsten 24 Stunden buchst!
		}
		<br />
		<br />
	</div>
	<button class="m-auto mt-4 bg-indigo hover:bg-indigo-2 text-white font-bold rounded-full text-text-1 w-[320px] px-[18px] py-[8px] max-w-full" (click)="closeDialog()">
		Zeige mir meine Möglichkeiten!
	</button>
</div>