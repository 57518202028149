import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
	AbstractControl,
	FormGroup,
	ReactiveFormsModule,
	UntypedFormControl,
	Validators
} from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogModule
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IconService } from "@shared/services/icon.service";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { ButtonType } from "src/app/components/call-room/data/ButtonType";
import {
	REPEAT_TYPE_LIST,
	repeatType,
	RequestType
} from "src/app/date-and-time/date-and-time.component";
import { AvailabilityService } from "src/app/services/availability/availability.service";
import { LessonsService } from "src/app/services/lessons/lessons.service";
import { PopupService } from "src/app/services/popup/popup.service";
import { Student } from "src/app/students/students.service";
import {
	CustomDateAdapter,
	addMinutesToTime,
	timePickerStringToUTC
} from "src/app/utils/convert-time.util";
import { LessonsStatus } from "../../../../constants/lessons-status.constants";
import { UtilityService } from "src/app/services/utility.service";
import { forkJoin } from "rxjs";
import { map, switchMap } from "rxjs/operators";

type EventType = "lesson" | "other";

// to make mat date picker display custom format 😵‍💫
export const MY_DATE_FORMATS = {
	parse: {
		dateInput: "input"
	},
	display: {
		dateInput: "input",
		monthYearLabel: "MMM yyyy",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "MMMM yyyy"
	}
};

@Component({
	selector: "app-calendar-event-dialog",
	standalone: true,
	imports: [
		MatDialogModule,
		MatSelectModule,
		ReactiveFormsModule,
		TranslateModule,
		CommonModule,
		MatFormFieldModule,
		MatIconModule,
		NgxMaterialTimepickerModule,
		MatInputModule,
		MatDatepickerModule,
		MatCheckboxModule,
		DragDropModule
	],
	providers: [
		{ provide: DateAdapter, useClass: CustomDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
	],
	templateUrl: "./calendar-event-dialog.component.html",
	styleUrl: "./calendar-event-dialog.component.scss"
})
export class CalendarEventDialogComponent implements OnInit {
	eventForm: FormGroup;
	studentList: Student[];
	userId: string;
	timeZone: string;
	lessonLength: number;
	defaultSlots = this.availabilityService.defaultSlots;
	@ViewChild("picker") picker: any;
	repeatTypeList = REPEAT_TYPE_LIST;
	eventType: EventType = "lesson";
	minDate = new Date();

	constructor(
		@Inject(MAT_DIALOG_DATA)
		private data: {
			userId: string;
			timeZone: string;
			studentId?: string;
		},
		private lessonsService: LessonsService,
		private availabilityService: AvailabilityService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private popupService: PopupService,
		private iconService: IconService,
		private utilityService: UtilityService
	) {
		this.userId = this.data.userId;
		this.timeZone = this.data.timeZone;
		this.defaultSlots = this.availabilityService.defaultSlots;
	}

	ngOnDestroy(): void {
		console.log("destroyed");
	}

	ngOnInit() {
		this.eventForm = new FormGroup({
			student: new UntypedFormControl("", [Validators.required]),
			date: new UntypedFormControl(new Date(), [Validators.required]),
			startTime: new UntypedFormControl("16:00", [Validators.required]),
			endTime: new UntypedFormControl("16:30", [Validators.required]),
			repeat: new UntypedFormControl(repeatType.NONE, [
				// to do, make NONE not falsy and put validators.required
				// Validators.required
			])

			// title: new UntypedFormControl("", [Validators.required]),
			// override: new UntypedFormControl(false, [Validators.required])
		});

		this.startTimeControl.setValue(
			this.utilityService.getTimeIn15MinSteps()
		);

		this.loadActiveRegularStudents();

		this.studentControl.valueChanges.subscribe((studentId) => {
			this.studentControl.disable({ emitEvent: false });
			const student = this.studentList.find((s) => s.id === studentId);
			if (student?.email) {
				this.lessonsService
					.getLessonPackageByStudentEmail(student.email)
					.subscribe((p) => {
						const duration = p?.duration || 60;
						this.lessonLength = Number(duration);
						this.endTimeControl.setValue(
							addMinutesToTime(
								this.startTimeControl.value,
								duration
							)
						);
						this.endTimeControl.disable({ emitEvent: false });
						this.studentControl.enable({ emitEvent: false });
					});
			}
		});

		this.startTimeControl.valueChanges.subscribe((st) => {
			if (this.eventType === "lesson" && this.lessonLength) {
				this.endTimeControl.setValue(
					addMinutesToTime(st, this.lessonLength)
				);
			}
		});
	}

	get studentControl(): AbstractControl {
		return this.eventForm.controls.student;
	}

	get endTimeControl(): AbstractControl {
		return this.eventForm.controls.endTime;
	}

	get startTimeControl(): AbstractControl {
		return this.eventForm.controls.startTime;
	}

	get dateControl(): AbstractControl {
		return this.eventForm.controls.date;
	}

	get repeatControl(): AbstractControl {
		return this.eventForm.controls.repeat;
	}

	close(): void {
		this.dialog.getDialogById("calendar-event-dialog").close();
	}

	openCalendar(): void {
		this.picker.open();
	}

	setEventType(eventType: EventType): void {
		this.eventType = eventType;
		this.onEventTypeChange(eventType);
	}

	loadActiveRegularStudents(): void {
		this.fetchAndFilterStudents().subscribe((filteredStudents) => {
			// Update the student list with the filtered students
			this.studentList = filteredStudents;

			// If a student ID is already selected and still present in the filtered list, set the value in the control
			if (
				this.data.studentId &&
				this.studentList.some(
					(student) => student.id === this.data.studentId
				)
			) {
				this.studentControl.setValue(this.data.studentId);
			}
		});
	}

	/**
	 * Fetch active regular students and filter them based on their lesson package status.
	 * @returns Observable of filtered student list.
	 */
	private fetchAndFilterStudents() {
		return this.lessonsService.getActiveRegularStudents(this.userId).pipe(
			switchMap((res) => {
				this.studentList = res.list;

				const lessonPackageObservables =
					this.createLessonPackageObservables();

				// Use forkJoin to wait until all observables complete
				return forkJoin(lessonPackageObservables);
			}),
			map((results) => this.filterStudentsByLessonPackage(results))
		);
	}

	/**
	 * Create an array of observables to get lesson packages for each student.
	 * @returns Array of observables that return student and their lesson package.
	 */
	private createLessonPackageObservables() {
		return this.studentList.map((student) =>
			this.lessonsService
				.getLessonPackageByStudentEmail(student?.email)
				.pipe(
					map((lessonPackage) => ({
						student, // Return the student object
						lessonPackage // Return the corresponding lesson package
					}))
				)
		);
	}

	/**
	 * Filter students based on the lesson package status.
	 * @param results Array of objects containing students and their lesson packages.
	 * @returns Filtered list of students with acceptable lesson package statuses, Active or Pastdue
	 */
	private filterStudentsByLessonPackage(
		results: { student: any; lessonPackage: any }[]
	): any[] {
		return results
			.filter(
				({ lessonPackage }) =>
					lessonPackage?.status === LessonsStatus.ACTIVE ||
					lessonPackage?.status === LessonsStatus.PASTDUE
			)
			.map(({ student }) => student);
	}

	onEventTypeChange(eventType: EventType): void {
		const student = this.eventForm.get("student");
		const title = this.eventForm.get("title");

		if (eventType === "lesson") {
			student.setValidators([Validators.required]);
			title.setValidators(null);
			if (this.lessonLength) {
				this.endTimeControl.setValue(
					addMinutesToTime(
						this.startTimeControl.value,
						this.lessonLength
					)
				);
				this.endTimeControl.disable({ emitEvent: false });
			}
		} else if (eventType === "other") {
			this.endTimeControl.enable({ emitEvent: false });
			student.setValidators(null);
			title.setValidators([Validators.required]);
		}
	}

	get isFormValid(): boolean {
		// check if start time is in the future
		const { startTime, date } = this.eventForm.getRawValue();
		let validationDate = new Date(date);
		let [hours, minutes] = startTime.split(":");
		validationDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);
		let now = new Date();

		if (this.eventForm.valid && now < validationDate) {
			return true;
		}
	}

	save() {
		const { startTime, endTime, student, date } =
			this.eventForm.getRawValue();

		let data = {
			startTime: timePickerStringToUTC(startTime, date),
			endTime: timePickerStringToUTC(endTime, date)
		};
		if (this.eventType === "lesson") {
			const bookingData = {
				...data,
				studentId: student,
				teacherId: this.userId,
				duration: this.lessonLength + "m",
				description: "",
				title: "",
				status: "accepted",
				notify: true,
				type:
					this.repeatControl.value == repeatType.NONE
						? "single"
						: "repeat"
			};

			if (this.repeatControl.value !== repeatType.NONE) {
				bookingData["repeat"] = true;
				bookingData["repeatSchema"] = {
					rhythm: this.repeatControl.value,
					amount: 3,
					startTime: timePickerStringToUTC(startTime, date),
					endTime: timePickerStringToUTC(endTime, date),
					duration: this.lessonLength + "m"
				};
			}

			const viewValue =
				this.repeatTypeList.find(
					(item) => item.value === this.repeatControl.value
				)?.title || "";

			let confirmData = {
				student: this.studentList.find((s) => s.id === student),
				date: bookingData.startTime,
				duration: bookingData.duration,
				target: "teacher",
				title: this.translate.instant("lessons.confirmation-title"),
				timeZone: "EU",
				requestType: RequestType.ADD,
				repeatType: viewValue ? this.translate.instant(viewValue) : ""
			};

			this.popupService.openConfirmLessonDialog(
				confirmData,
				bookingData,
				[
					{
						type: ButtonType.CANCEL,
						text: "lessons.cancel-at-confirmation"
					},
					{
						type: ButtonType.OK,
						text: "lessons.confirm-btn"
					}
				]
			);
		} else if (this.eventType === "other") {
		}
	}
}
