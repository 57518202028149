import { Injectable } from "@angular/core";
import { ComponentType } from "@angular/cdk/overlay";
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef
} from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { LobbyPopupDesktopComponent } from "../../components/util/popup/lobby/desktop/lobby-popup-desktop.component";
import { PlatformDetectorService } from "../platform-detector/platform-detector.service";
import { WarningPopupComponent } from "../../components/util/popup/warning/warning-popup.component";
import { RemovalPopupComponent } from "../../components/util/popup/removal/removal-popup.component";
import { LetInWaitingComponent } from "../../components/util/popup/let-in-waiting/let-in-waiting.component";
import { SnackBarMessageComponent } from "../../components/util/popup/snack-bar-message/snack-bar-message.component";
import { SNACKBAR_DURATION } from "../../constants/generic.constants";
import { CustomMessageComponent } from "../../components/util/popup/custom-message/custom-message.component";
import { ButtonType } from "../../components/call-room/data/ButtonType";
import { RoomInviteInfoComponent } from "../../components/util/popup/room-invite-info/room-invite-info.component";
import { ExpirationFreePlanComponent } from "../../components/util/popup/expiration-free-plan/expiration-free-plan.component";
import { RoomFullComponent } from "../../components/util/popup/room-full/room-full.component";
import { AccessDeniedComponent } from "../../components/util/popup/access-denied/access-denied.component";
import { SettingPopupComponent } from "src/app/lib-setting/components/setting-popup/setting-popup.component";
import { RecordWarningComponent } from "../../components/util/popup/record-warning/record-warning.component";
import { YouTubeComponent } from "../../components/util/popup/youtube/youtube.component";
import { SelectStudentDialogComponent } from "../../components/util/popup/select-student/select-student-dialog.component";
import {
	ConfirmData,
	ConfirmLessonComponent
} from "../../components/util/popup/confirm-lesson/confirm-lesson.component";
import { RescheduleLessonComponent } from "../../components/util/popup/reschedule-lesson/reschedule-lesson.component";
import { RescheduleCalendarComponent } from "../../components/util/popup/reschedule-calendar/reschedule-calendar.component";
import { Profile } from "src/app/types/profile.types";
import { MusicModalComponent } from "src/app/music-details-selector/modal/music-modal.component";
import { AddOverrideComponent } from "../../add-override/add-override.component";
import { StudentInfoComponent } from "src/app/components/util/popup/student-info/student-info.component";
import { InviteExternalStudentComponent } from "../../components/util/popup/invite-external-student/invite-external-student.component";
import { DateAndTimeComponent } from "src/app/date-and-time/date-and-time.component";
import { AvailabilityCalendarComponent } from "src/app/availability-calendar/availability-calendar.component";
import { Student } from "src/app/students/students.service";
import { AcceptDeclineBookingComponent } from "src/app/accept-decline-booking/accept-decline-booking.component";
import { RecordingListItem } from "../recording/recording.service";
import { RecordingDetailsComponent } from "src/app/components/util/popup/recording-details/recording-details.component";
import { LessonInfoComponent } from "src/app/components/util/popup/lesson-info/lesson-info.component";
import { Booking } from "../lessons/lessons.service";
import { SubRole } from "src/app/constants/subroles-constants";
import { CalendarEventDialogComponent } from "src/app/components/util/popup/calendar-event-dialog/calendar-event-dialog.component";
import { CheckoutPopupComponent } from "src/app/components/util/popup/checkout-popup/checkout-popup.component";
import { EditRepeatBookingComponent } from "src/app/components/util/popup/edit-repeat-booking/edit-repeat-booking.component";
import { LessonsSummaryComponent } from "src/app/components/util/popup/lessons-summary/lessons-summary.component";

interface LessonInfoDialogOptions {
	booking: Booking;
	timeZone: string;
	subRole: SubRole;
	readOnly?: boolean;
	status: string;
}
@Injectable()
export class PopupService {
	// Device detection
	isDesktop: boolean = true;
	isMobile: boolean = false;
	isTablet: boolean = false;

	constructor(
		private dialog: MatDialog,
		protected platformDetectorService: PlatformDetectorService,
		private snackBar: MatSnackBar
	) {
		// Device detection
		this.isMobile = this.platformDetectorService.isMobile();
		this.isTablet = this.platformDetectorService.isTablet();
		this.isDesktop = this.platformDetectorService.isDesktop();
	}

	openWarningPopup(warning: string): Promise<any> {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = warning;
		// Check if we already have a dialog with the same message opened
		if (
			this.dialog.openDialogs &&
			this.dialog.openDialogs.find((instance) => {
				return instance.componentInstance.warning === warning;
			})
		) {
			return;
		}
		dialogConfig.disableClose = true;
		const dialogRef = this.dialog.open(WarningPopupComponent, dialogConfig);
		const answer = new Promise((resolve) => {
			dialogRef.afterClosed().subscribe((result) => {
				resolve(result);
			});
		});
		return answer;
	}

	openLetInWaitingDialog(): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "let-in-waiting-dialog";
		dialogConfig.disableClose = true;
		const dialogRef = this.dialog.open(LetInWaitingComponent, dialogConfig);
		//dialogRef.afterClosed().subscribe(result => {
		//   this.closeLobbyPopup();
		//});
	}

	closeLetInWaitingDialog(): void {
		try {
			const waitingDialog = this.dialog.getDialogById(
				"let-in-waiting-dialog"
			);
			if (!!waitingDialog) {
				waitingDialog.close();
			}
		} catch (error) {
			console.error("Error closing loading popup: ", error);
		}
	}

	openSnackBar(message: string, duration: number = SNACKBAR_DURATION): void {
		const config = new MatSnackBarConfig();
		config.duration = duration;
		config.data = message;
		this.snackBar.openFromComponent(SnackBarMessageComponent, config);
	}

	closeAccessPopup(keyToClose: string): void {
		const popupToClose = this.dialog.openDialogs.find((instance) => {
			return instance.componentInstance.key === keyToClose;
		});
		if (popupToClose) {
			popupToClose.close();
		}
	}

	openRemovalPopup(name: string): Promise<any> {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = name;
		dialogConfig.disableClose = true;
		const dialogRef = this.dialog.open(RemovalPopupComponent, dialogConfig);
		const answer = new Promise((resolve) => {
			dialogRef.afterClosed().subscribe((result) => {
				resolve(result);
			});
		});
		return answer;
	}

	openExpirationFreePlanPopup(countDaysTariffFree: number): Promise<any> {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = { countDaysTariffFree, result: true };
		dialogConfig.disableClose = true;
		dialogConfig.panelClass = "app-panel-expiration-free-plan";
		const dialogRef = this.dialog.open(
			ExpirationFreePlanComponent,
			dialogConfig
		);
		const resultPromise = new Promise((resolve) => {
			dialogRef.afterClosed().subscribe((result) => {
				resolve(result);
			});
		});
		return resultPromise;
	}

	private openPopup(
		data: any,
		popupClass: ComponentType<any>,
		isWaitingRoom: boolean = false
	): Promise<any> | any {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			type: data.type,
			title: data.title,
			isLive: data.live,
			users: data.users,
			usersConnected: data.connected,
			maxUsersAmount: data.roominess,
			errorMessage: data.message
		};
		dialogConfig.disableClose = true;
		if (isWaitingRoom) {
			dialogConfig.id = "lobby-popup";
		}
		const dialogRef = this.dialog.open(popupClass, dialogConfig);
		return dialogRef;
	}

	openLobbyPopup(data: any): any {
		return this.openPopup(data, LobbyPopupDesktopComponent, true);
	}

	closeLobbyPopup(): void {
		try {
			const panelDialog = this.dialog.getDialogById("lobby-popup");
			if (!!panelDialog) {
				panelDialog.close();
			}
		} catch (error) {
			console.error("Error closing loading popup: ", error);
		}
	}

	openCustomMessagePopup(
		text: string,
		buttons: { type: ButtonType; text: string; callBack?: () => void }[],
		isReverse: boolean = false,
		singleBtn = false
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = { text, buttons, isReverse, singleBtn };
		dialogConfig.disableClose = true;
		this.dialog.open(CustomMessageComponent, dialogConfig);
	}

	openRecordWarning(
		text: string,
		title: string,
		buttons: { type: ButtonType; text: string; callBack?: () => void }[],
		isReverse: boolean = false
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = { text, title, buttons, isReverse };
		dialogConfig.disableClose = true;
		this.dialog.open(RecordWarningComponent, dialogConfig);
	}

	openYoutubeDialog(
		text: string,
		title: string,
		buttons: {
			type: ButtonType;
			text: string;
			callBack?: (url: string) => void;
		}[],
		isReverse: boolean = false
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "youtube-dialog";
		dialogConfig.data = {
			text,
			title,
			buttons,
			isReverse,
			dialog: this.dialog
		};
		dialogConfig.disableClose = true;
		this.dialog.open(YouTubeComponent, dialogConfig);
	}

	openSelectStudentDialog(
		userId: string,
		timeZone: string,
		buttons: {
			type: ButtonType;
			text: string;
			callBack?: (data: Student) => void;
		}[]
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "select-student-dialog";
		dialogConfig.data = {
			buttons,
			dialog: this.dialog,
			userId,
			timeZone
		};
		dialogConfig.disableClose = true;
		this.dialog.open(SelectStudentDialogComponent, dialogConfig);
	}

	/**
	 * @param {ConfirmData} confirmData props for confirm lesson dialog
	 * @param bookingData for sending to server
	 * @param buttons
	 */
	openConfirmLessonDialog(
		confirmData: ConfirmData,
		// to do - create type for bookingData
		bookingData: any,
		buttons: {
			type: ButtonType;
			text: string;
			callBack?: (data: any) => void;
		}[]
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "confirm-lesson-dialog";
		dialogConfig.data = { buttons, confirmData, bookingData };
		dialogConfig.disableClose = true;
		this.dialog.open(ConfirmLessonComponent, dialogConfig);
	}

	openAddOverrideDialog(
		data: any,
		buttons: {
			type: ButtonType;
			text: string;
			callBack?: (data: any) => void;
		}[]
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "add-override-dialog";
		dialogConfig.data = { buttons, data };
		dialogConfig.height = "auto";
		dialogConfig.disableClose = true;
		this.dialog.open(AddOverrideComponent, dialogConfig);
	}

	openRoomInfoDialog(data: { link; password }) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = data;
		dialogConfig.disableClose = true;
		this.dialog.open(RoomInviteInfoComponent, dialogConfig);
	}

	openRoomFullDialog(): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		const dialogRef = this.dialog.open(RoomFullComponent, dialogConfig);
		dialogRef.afterClosed().subscribe((result) => {
			this.closeLobbyPopup();
		});
	}

	openAccessDeniedDialog(): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		const dialogRef = this.dialog.open(AccessDeniedComponent, dialogConfig);
		dialogRef.afterClosed().subscribe((result) => {
			this.closeLobbyPopup();
		});
	}

	openMusicDialog(profile: Profile): void {
		const musicDialogOpened =
			window.sessionStorage.getItem("musicDialogOpened");
		if (!profile.instruments?.length && !musicDialogOpened) {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.data = profile;
			dialogConfig.disableClose = true;
			this.dialog.open(MusicModalComponent, dialogConfig);
			window.sessionStorage.setItem("musicDialogOpened", "true");
		}
	}

	settings(
		returnedType: "MediaStreamConstraints" | "MediaStream" = "MediaStream",
		initParams?: any
	): MatDialogRef<any> {
		return this.dialog.open(SettingPopupComponent, {
			minHeight: "40vh",
			panelClass: "app-dialog",
			disableClose: true,
			data: { returnedType, params: initParams || null }
		});
	}

	closeAll(): void {
		this.dialog.closeAll();
	}

	openRescheduleLessonDialog(
		data: any,
		buttons: {
			type: ButtonType;
			text: string;
			callBack?: (data: any, textareaValue: string) => void;
		}[]
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "reschedule-lesson-dialog";
		dialogConfig.data = { buttons, data };
		dialogConfig.disableClose = true;
		this.dialog.open(RescheduleLessonComponent, dialogConfig);
	}

	openRescheduleCalendarDialog(
		bookingId: string,
		studentName: string,
		textareaValue: string,
		timeZone: string,
		buttons: { type: ButtonType; text: string }[]
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "reschedule-calendar-dialog";
		dialogConfig.data = {
			buttons,
			dialog: this.dialog,
			studentName,
			timeZone,
			bookingId: bookingId,
			textareaValue: textareaValue
		};
		dialogConfig.disableClose = true;
		this.dialog.open(RescheduleCalendarComponent, dialogConfig);
	}

	openInviteStudenDialog(callback: any = () => {}) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "invite-student-dialog";
		dialogConfig.data = { dialog: this.dialog };
		dialogConfig.disableClose = false;
		let dialogRef = this.dialog.open(
			InviteExternalStudentComponent,
			dialogConfig
		);
		dialogRef.afterClosed().subscribe(() => {
			callback();
		});
	}

	openMoreLessonInfoModal(date: string, time: string, student: any) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "student-info-dialog";
		dialogConfig.data = { date, time, student };
		this.dialog.open(StudentInfoComponent, dialogConfig);
	}

	openRecordingDetailsDialog(
		recording: RecordingListItem
	): MatDialogRef<any> {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "recording-details-dialog";
		dialogConfig.data = recording;
		return this.dialog.open(RecordingDetailsComponent, dialogConfig);
	}

	dateAndTime(
		type: "booking" | "reschedule",
		student: any,
		userId?: string,
		bookingId?: string,
		textareaValue?: string,
		timeZone?: string
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "date-and-time-dialog";
		dialogConfig.data = {
			student,
			userId,
			dialog: this.dialog,
			bookingId,
			textareaValue,
			timeZone,
			type
		};
		this.dialog.open(DateAndTimeComponent, dialogConfig);
	}

	openAvailabilityCalendar() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "availability-calendar-dialog";
		dialogConfig.data = {};
		this.dialog.open(AvailabilityCalendarComponent, dialogConfig);
	}

	openUpdateBookingDialog(booking, status: string) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "update-booking-dialog";
		dialogConfig.data = { booking: booking, status: status };
		this.dialog.open(AcceptDeclineBookingComponent, dialogConfig);
	}
	openLessonInfoDialog(options: LessonInfoDialogOptions) {
		const { booking, timeZone, subRole, readOnly, status } = options;
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "lesson-info-dialog";
		dialogConfig.data = { booking, timeZone, subRole, readOnly, status };
		this.dialog.open(LessonInfoComponent, dialogConfig);
	}

	openCalendarEventDialog(
		userId: string,
		timeZone: string,
		backdrop: boolean = false,
		studentId?: string
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.hasBackdrop = backdrop;
		dialogConfig.id = "calendar-event-dialog";
		dialogConfig.data = { userId, timeZone, studentId: studentId };
		this.dialog.open(CalendarEventDialogComponent, dialogConfig);
	}

	openCheckoutDialog(teacher: string) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "checkout-dialog";
		dialogConfig.data = { teacher };
		this.dialog.open(CheckoutPopupComponent, dialogConfig);
	}

	openEditRepeatBookingDialog(
		teacherId: string,
		studentId: string,
		studentName: string
	) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.panelClass = "app-dialog";
		dialogConfig.data = { teacherId, studentId, studentName };
		dialogConfig.id = "edit-repeat-booking-dialog";
		dialogConfig.width = "300px";
		this.dialog.open(EditRepeatBookingComponent, dialogConfig);
	}

	openLessonSummaryDialog(classData: any) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = { ...classData };
		dialogConfig.disableClose = true;
		this.dialog.open(LessonsSummaryComponent, dialogConfig);
	}
}
