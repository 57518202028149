import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IconService } from "@shared/services/icon.service";
import { SubRole } from "src/app/constants/subroles-constants";
import { LanguageService } from "src/app/services/languages/language.service";
import {
	Booking,
	LessonsService
} from "src/app/services/lessons/lessons.service";
import {
	convertToDateWithYear,
	convertToTime,
	getDayOfWeek
} from "src/app/utils/convert-time.util";

@Component({
	selector: "app-lesson-info",
	standalone: true,
	imports: [CommonModule, TranslateModule, MatIconModule],
	templateUrl: "./lesson-info.component.html",
	styleUrl: "./lesson-info.component.scss"
})
export class LessonInfoComponent {
	name: string = "";
	date: string = "";
	startTime: string = "";
	endTime: string = "";
	instruments = "";
	genre = "";
	ageGroup = "";
	favouriteSong = "";
	notesForTeacher = "";
	skillLevel = "";
	type = "";
	typeString = "";
	timeZone: string;
	subRole: SubRole;
	duration: string;
	lessonColor = "blue";
	detailsString: string;
	readonly = false;
    status: string;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		private data: {
			booking: Booking;
			timeZone: string;
			subRole: SubRole;
			readOnly: boolean;
            status: string;
		},
		private dialogRef: MatDialogRef<LessonInfoComponent>,
		private languageService: LanguageService,
		private translate: TranslateService,
		private lessonsService: LessonsService,
		private iconService: IconService
	) {}

	ngOnInit() {
		const { booking, timeZone, subRole } = this.data;

		this.timeZone = timeZone;
		this.subRole = subRole;
		this.readonly = this.data.readOnly;
        this.status = this.data.status;
		// this.startTime = convertToTime(booking.startTime, this.timeZone);
		// this.date = convertToDate(booking.startTime, this.timeZone);

		const { student, type, duration } = booking;

		if (type === "trial") {
			this.typeString = this.translate.instant("lessons.trial-lesson");
			this.lessonColor = "#FAEAEC";
		} else {
			this.typeString = this.translate.instant("lessons.regular-lesson");
			this.lessonColor = "#908af5";
		}

		this.type = type;
		this.duration = duration.split("m")[0];
		this.name = student.name;

	
	

		this.instruments = this.languageService.translateArray(
			"instruments",
			student.studentProfile?.instruments?.map((i) => i.instrument)
		);

		const genres = student.studentProfile?.instruments
			?.map((i) => i.genre)
			?.flat();

		const genresSetString = [...new Set(genres)] as string[];

		this.genre = this.languageService.translateArray(
			"musicGenres",
			genresSetString
		);

		this.ageGroup = student.studentProfile?.ageGroup;
		this.favouriteSong = student.studentProfile?.favoriteSong;
		this.notesForTeacher = student.studentProfile?.notes;
		this.skillLevel = student.studentProfile?.skillLevel;

		this.detailsString = this.createDetailsString(booking);
	}

	close() {
		this.dialogRef.close();
	}

	reschedule() {
		this.dialogRef.close();
		this.lessonsService.rescheduleLesson(this.data.booking, this.timeZone);
	}

	startLesson() {
		this.dialogRef.close();
		this.lessonsService.startSession(this.data.booking, this.subRole);
	}

	createDetailsString(booking: Booking) {
		const { startTime, endTime } = booking;
		const day = convertToDateWithYear(startTime, this.timeZone);
		const start = convertToTime(startTime, this.timeZone);
		const end = convertToTime(endTime, this.timeZone);

		const dayOfWeek = getDayOfWeek(
			startTime,
			this.timeZone,
			this.translate.currentLang
		);

		return `${dayOfWeek}, ${day} · ${start}-${end}`;
	}

    acceptRequest() {
        this.lessonsService.updateBooking(this.data.booking.id, { status: "accepted" }).subscribe(() => {
            this.dialogRef.close();
        });
	}

	declineRequest() {	
        this.lessonsService.updateBooking(this.data.booking.id, { status: "declined" }).subscribe(() => {
            this.dialogRef.close();
        }
    )
	}
}
