import { SubRole } from "../constants/subroles-constants";
import { Tariff } from "./tariff-constants";

export enum TeacherStatus {
	SIGN_UP = "sign_up",
	REVIEW = "review",
	REJECTED = "rejected",
	NEW_ONBOARDED = "new_onboarded",
	NORMALLY_UTILIZED = "normally_utilized",
	HIGHLY_UTILIZED = "highly_utilized",
	FULLY_BOOKED = "fully_booked"
}

export type AgeGroup = '0-10' | '11-17' | '18-29' | '30-44' | '45-64' | '65-100';

export type SkillLevel = 'experience_no' | 'experience_self' | 'experience_1-3' | 'experience_more';

export type Gender = 'female' | 'male' | 'non-binary' | 'transgender' | 'intersex' | 'other' | 'prefer-not';

export type TeacherInstrument = {
	genre: string[];
	degree: string;
	instrument: string;
	certification: string;
	higherEducation: string;
	degreeDescription: string;
};

export type SubscriptionTariff = {
	type: Tariff;
	left: number;
	expired: boolean;
};

export type Notifications = {
	email: {
		trial: NotificationSettings;
		repeat: NotificationSettings;
		single: NotificationSettings;
	};
};

export type NotificationSettings = {
	reminder1h: boolean | null;
	reschedule: boolean | null;
	reminder24h: boolean | null;
	cancellation: boolean | null;
	confirmation: boolean | null;
};

interface SpokenLanguage {
	language: string;
	level: string;
}

export type Profile = {
	//common properties
	userId: string;
	firstName: string;
	lastName: string;
	timezone: string;
	email: string;
	subrole: SubRole;
	languageId: string;
	betaTesting: boolean;
	subscription: SubscriptionTariff | null;
	spokenLanguages?: SpokenLanguage[] | null;
	country: string | null;
	instruments?: string[] | null | TeacherInstrument[];

	// Student specific properties
	musicGenres?: string[] | null;

	// Matched teacher specific properties

	teacherStatus?: TeacherStatus;
	enablePersonalRoom?: boolean;
	allowExternals?: boolean;
	lessonOverview?: any | null;
	teacherWorkload?: string | null;
	notes?: string | null;
	teacherIntroduction?: string | null;
	gender?: string | null;
	dob?: string | null;
	socials?: string[] | null;
	phoneNumber?: string | null;
	photo?: string | null;
	teachingExperience?: string | null;
	studentPreferences?: string | null;
	preferenceComment?: string | null;
	accountName?: string | null;
	taxNumber?: string | null;
	taxAddress?: string | null;
	vatStatus?: string | null;
	iban?: string | null;
	bic?: string | null;
	notifications?: Notifications;
	confirmAge?: boolean | null;
	motivateStudents?: string | null;
	device?: string | null;
	browser?: string | null;
	uploadMbps?: number | null;
	externalMic?: boolean | null;
	externalCamera?: boolean | null;
	secondCamera?: boolean | null;
	matchesNumberPreference?: number | null;
	gendersPreference?: string[] | null;
	agesPreference?: string[] | null;
	skillLevelPreference?: string[] | null;
	payoutDetailsComplete?: boolean | null;
	criminalHistoryConfirmation?: boolean | null;
	termsConfirmation?: boolean | null;
	ageConfirmation?: boolean | null;
};
