<div class="app-availability-calendar min-w-96">
    @switch (formStep) {
    @case ('chooseTeacher'){
    <div class="row p-4">
        <p class="font-bold title mb-2">{{ "lessons.select-teacher" | translate }}</p>
        <mat-form-field>
            <mat-label>{{ "lessons.teacher" | translate }}</mat-label>
            <mat-select (selectionChange)="selectTeacher($event)">
                @for (teacher of teachers; track teacher) {
                <mat-option [value]="teacher">{{teacher.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    }
    @case ('chooseDateAndTime'){
    <div class="row flex flex-col md:flex-row availability-calendar" [@openClose]="isInfoOpen ? 'open' : 'closed'">

        <div class="col p-2">
            <div class="teacher-name-photo flex items-center mb-3">
                <img style="border-radius: 50%; width: 80px; height: 80px; object-fit: cover;"
                    class="hidden md:block mr-2" src="{{selectedTeacherPhoto}}" alt="">
                <span class="font-bold">{{selectTeacherName}}</span>
            </div>
            <p>{{duration}} {{ "lessons.minute-music-lesson" | translate }}</p>

        </div>

        <div *ngIf="selectedTeacherId" class="col border-r border-l border-gray-300">
            <mat-calendar [dateFilter]="availabilityDaysFilter" [minDate]="getDateOfTomorrow()"
                (selectedChange)="onDateChange($event)" [(selected)]="selected"></mat-calendar>
        </div>

        <div class="col p-2 pt-4 max-h-full md:max-h-[500px] overflow-visible md:overflow-scroll">
            <p>{{templateDate}}</p>
            @for (availabilityTimeslot of slots; track availabilityTimeslot) {
            <button class="time-slot mb-2" (click)="selectTimeSlot(availabilityTimeslot)">
                {{availabilityTimeslot.startTime}} - {{availabilityTimeslot.endTime}} <br>
            </button>
            }
            @empty {
            <p>{{ "lessons.no-availabilities" | translate }}</p>
            }
        </div>
    </div>
    }
    @case ("confirm") {
    <div style="min-width: 320px;" class="row p-2 confirm">
        <p class="mb-2 font-bold">{{"lessons.request-string" | translate }} {{selectTeacherName}} </p>
        <p>{{templateDate}}, {{selectedTimeSlot.startTime}} – {{selectedTimeSlot.endTime}} </p>
    </div>
    <div class="row flex p-2 justify-end min-w-full">
        <button class="btn mr-2" (click)="goBack()">{{
            "lessons.back" | translate }}</button>
        <button class="schedule-btn" (click)="confirmBookingRequest()">{{
            "lessons.send-request" | translate }}</button>
    </div>
    }
    @case ("success" ) {
    <div style="min-width: 320px;" class="row p-2 success">
        <p>{{
            "lessons.request-sent" | translate }}</p>
    </div>
    }
    @case ("error" ) {
    <div style="min-width: 320px;" class="row p-2 success">
        <p class="text-red-500">
            {{
            "lessons.error-booking-exists" | translate }}
        </p>
    </div>
    }
    }
</div>