import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "../authentication/authentication.service";
import { Observable } from "rxjs";
import { AVAILABILITY } from "../../constants/connection-constants";
import { get } from "http";

export interface Availability {
	id: string;
	userId: string;
	day: number;
	overrideDate: string | null;
	startTime: string | null;
	endTime: string | null;
	notice: number;
	timezone: string | null;
	bookingLimit: number;
}

export type AvailabilityType = "regular" | "trial";

@Injectable({
	providedIn: "root"
})
export class AvailabilityService {
	defaultSlots = [
		{ slot: "06:00" },
		{ slot: "06:15" },
		{ slot: "06:30" },
		{ slot: "06:45" },
		{ slot: "07:00" },
		{ slot: "07:15" },
		{ slot: "07:30" },
		{ slot: "07:45" },
		{ slot: "08:00" },
		{ slot: "08:15" },
		{ slot: "08:30" },
		{ slot: "08:45" },
		{ slot: "09:00" },
		{ slot: "09:15" },
		{ slot: "09:30" },
		{ slot: "09:45" },
		{ slot: "10:00" },
		{ slot: "10:15" },
		{ slot: "10:30" },
		{ slot: "10:45" },
		{ slot: "11:00" },
		{ slot: "11:15" },
		{ slot: "11:30" },
		{ slot: "11:45" },
		{ slot: "12:00" },
		{ slot: "12:15" },
		{ slot: "12:30" },
		{ slot: "12:45" },
		{ slot: "13:00" },
		{ slot: "13:15" },
		{ slot: "13:30" },
		{ slot: "13:45" },
		{ slot: "14:00" },
		{ slot: "14:15" },
		{ slot: "14:30" },
		{ slot: "14:45" },
		{ slot: "15:00" },
		{ slot: "15:15" },
		{ slot: "15:30" },
		{ slot: "15:45" },
		{ slot: "16:00" },
		{ slot: "16:15" },
		{ slot: "16:30" },
		{ slot: "16:45" },
		{ slot: "17:00" },
		{ slot: "17:15" },
		{ slot: "17:30" },
		{ slot: "17:45" },
		{ slot: "18:00" },
		{ slot: "18:15" },
		{ slot: "18:30" },
		{ slot: "18:45" },
		{ slot: "19:00" },
		{ slot: "19:15" },
		{ slot: "19:30" },
		{ slot: "19:45" },
		{ slot: "20:00" },
		{ slot: "20:15" },
		{ slot: "20:30" },
		{ slot: "20:45" },
		{ slot: "21:00" },
		{ slot: "21:15" },
		{ slot: "21:30" },
		{ slot: "21:45" },
		{ slot: "22:00" },
		{ slot: "22:15" },
		{ slot: "22:30" },
		{ slot: "22:45" },
		{ slot: "23:00" },
		{ slot: "23:15" },
		{ slot: "23:30" }
	];

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	private get authHttpOptions() {
		return {
			withCredentials: true,
			headers: new HttpHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: this.auth.accessToken
			})
		};
	}

	getDefaultAvailabilityData(lessonType: string) {
		const defaultAvailability = [];
		for (let i = 0; i < 7; i++) {
			// const start: Date = new Date();
			// start.setUTCHours(8,0,0);
			// const end = new Date();
			// end.setUTCHours(17, 0,0)
			// let startTime = start.toISOString();
			// let endTime = end.toISOString();
			// // unchecked Sunday for test
			// if (i === 6) {
			// 	 startTime = null;
			// 	 endTime = null;
			// }

			// unchecked for hole week
			let startTime = null;
			let endTime = null;

			defaultAvailability.push({
				type: lessonType,
				day: i,
				startTime,
				endTime
			});
		}
		return defaultAvailability;
	}

	getAvailability(userId: string, type: string): Observable<any> {
		return this.http.get(
			`${AVAILABILITY}/${userId}?type=${type}`,
			this.authHttpOptions
		);
	}

	addAvailability(userId: string, availability): Observable<any> {
		const data = {
			availability
		};
		return this.http.post(
			`${AVAILABILITY}/${userId}`,
			data,
			this.authHttpOptions
		);
	}

	addOverride(userId: string, data): Observable<any> {
		return this.http.post(
			`${AVAILABILITY}/${userId}/override`,
			data,
			this.authHttpOptions
		);
	}

	deleteOverride(availabilityId: string): Observable<any> {
		return this.http.delete(
			`${AVAILABILITY}/${availabilityId}`,
			this.authHttpOptions
		);
	}

	updateNotice(
		userId: string,
		notice: number,
		lessonType: string
	): Observable<any> {
		const data = {
			type: lessonType,
			notice
		};
		return this.http.put(
			`${AVAILABILITY}/${userId}/notice`,
			data,
			this.authHttpOptions
		);
	}

	updateLimit(
		userId: string,
		limit: number,
		lessonType: string
	): Observable<any> {
		const data = {
			type: lessonType,
			bookingLimit: limit
		};
		return this.http.put(
			`${AVAILABILITY}/${userId}/limit`,
			data,
			this.authHttpOptions
		);
	}

	getOverrides(userId: string, type): Observable<any> {
		// to do: remove hard coded type & create type enum!
		return this.http.get(
			`${AVAILABILITY}/${userId}/override?type=${type}`,
			this.authHttpOptions
		);
	}
}
