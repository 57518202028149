import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const SIZE_LIMIT = 5_242_880; // 5MB
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png"];

enum Error {
	SIZE = "photo-upload.error.size",
	FORMAT = "photo-upload.error.format",
	OTHER = "photo-upload.error.other"
}

@Component({
	selector: "app-photo-upload",
	templateUrl: "./photo-upload.component.html",
	styleUrls: ["./photo-upload.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PhotoUploadComponent),
			multi: true
		}
	]
})
export class PhotoUploadComponent implements ControlValueAccessor {
	preview: string;
	error: null | Error = null;
	file: File;

	onChange: any = () => {};
	onTouched: any = () => {};

	writeValue(val: any): void {
		if (val) {
			this.preview = val;
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public onFileChanged(event) {
		this.error = null;
		this.file = event.target.files[0];

		if (this.file) {
			if (this.file.size > SIZE_LIMIT) {
				this.error = Error.SIZE;
			} else if (!ALLOWED_FILE_TYPES.includes(this.file.type)) {
				this.error = Error.FORMAT;
			} else {
				this.preview = "";
				const reader = new FileReader();
				reader.onload = (event: any) => {
					this.preview = event.target.result;
					this.onChange(this.file);
				};
				reader.readAsDataURL(this.file);
			}
		}
	}
}
